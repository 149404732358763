import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import ActiveItemGallery from "@components/ActiveItemGallery"

import {
  RequestMoreInfo,
} from "@components/Forms"

const galleryItems = [
  {
    key: 1,
    title: "Front",
    images: ["/images/guitar001-body-front.jpg"],
  },
  {
    key: 2,
    title: "Back",
    images: ["/images/guitar001-body-back.jpg"],
  },
  {
    key: 3,
    title: "Side",
    images: ["/images/guitar001-body-side.jpg"],
  },
  {
    key: 4,
    title: "Neck Back",
    images: ["/images/guitar001-neck-back.jpg"],
  },
]

const Guitar001 = () => {
  return (
    <PageLayout>
      <SEO
        title="T-Style Guitar | Purple Paisley Fabric Top | King Electric Guitars"
        ogDescription="This is T-style guitar that started it all. It got the attention of future clients and was the foundation of King Electric Guitars. Let's build your dream guitar!"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div className="content">
            <h1>T-Style Guitar with Purple Paisley Fabric Top</h1>
            <p className="detail">
              <b>Guitar:</b> T-Style Prototype #1
            </p>
            <p className="detail">
              <b>Finish:</b> Purple Paisley Fabric Top on Mahogany Body with maple neck
            </p>
            <p className="detail">
              <b>Details:</b> T-Style body with dual P90 Bootstrap pickups
            </p>
          </div>
        }
      />
      <ActiveItemGallery
        items={galleryItems}
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>This Guitar's Story</h2>
            <p>
              This is the guitar that started it all for King Electric Guitars. First, let me tell ya a little backstory.
            </p>
            <p>
              I had a dream as a kid for my dad and me to make some guitars. he was a skilled woodworker & a guitar player so it only made sense, but that never happened. After High School, I went into the Military (USAF) then got out & started playing in countless bands, all the while tinkering and repairing my own personal guitars. When my Dad passed away some 20 odd years later, I quit making excuses and got to work. with very limited tools & resources, I bought a kit guitar & made it my own.Never being one to leave well enough alone, a lesson learned from my love of classic custom cars & hot rods  I cut, molded, sanded, shaped & painted that kit guitar into what I wanted it to be. I enjoyed the process so much that I wanted more. I found an apprenticeship to feed my need. During this time, I learned guitar repair and a bit of guitar building here and there and saw lots of "guitar-shaped objects" brought in by aspiring guitar builders, 
            </p>
            <p>
              5 years later the Pandemic hit & due to family obligations, my apprenticeship was put on hold. I was newly married, just bought a house with a shop, and happened upon a large stash of hardwood at a steal, You see where this is going right? 
            </p>
            <p>
              I had sold my Tele a few years before this to buy tools, so it was a natural choice for my first build. I used some of the mahogany I'd recently purchased  & a set of templates and started building my first complete " from scratch" guitar. The second I chose to build a T-style guitar, I knew that I wanted a paisley top & back and the purple was chosen as a tribute to a fallen band member. I found the fabric at a craft store & the " cement gray" paint on the sides was seen on a Toyota truck. ( I have a database in my head of cool & unusual car colors that I plan on using one day) I then designed the guitar from those elements. I took my time and figured out things as I went along. My years of guitar repairs served me well during this process. When finished, the guitar ended up being more than a "guitar-shaped object", It played well, sounded good, & looked cool! This guitar got the attention of future clients and was the foundation of King Electric Guitars.
            </p>
            <h2>Specifications</h2>
            <ul>
              <li>Maple Neck and Fretboard</li>
              <li>25.5-inch Scale</li>
              <li>12-inch Fretboard Radius</li>
              <li>Med Cryowire Frets</li>
              <li>Dual Action Truss Rod</li>
              <li>Bolt on Neck With Inserts & Stainless Machine Screws</li>
              <li>Bone Nut</li>
              <li>Hipshot Locking Open Gear Tuners</li>
              <li>Mahogany Body</li>
              <li>Purple Paisley Fabric Top & Back</li>
              <li>Binding Top & Back</li>
              <li>Cement Gray Painted Sides</li>
              <li>Urethane Topcoat</li>
              <li>Wilkinson Bridge With Compensated Saddles</li>
              <li>Reversed Control Plate With Angled Switch</li>
              <li>Bootstrap P90 Pickups</li>
              <li>CTS 250k Pots</li>
              <li>Switchcraft 3-way Switch</li>
              <li>Switchcraft Jack</li>
              <li>.047 Orange Drop Cap</li>
              <li>Vintage Cloth Wire</li>
            </ul>
            <h2>Price and Availability</h2>
            <p>
              <strong>Being auctioned for charity. Details coming soon!</strong>
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Want me to build you something like it? Let's talk!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default Guitar001